:root {
  --amplify-primary-color: #f53672;
  --amplify-secondary-color: #24ad24;
}

.TitleBar {
  background-color: #24ad24;
  color: white;
}

.container {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.Section {
  margin-top: 10px;
  justify-content: center;
}

.SectionHeader {
  background-color: #24ad24;
  color: white;
}

.SectionBody {
  background-color: #aedda3;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  color: white;
  display: flex;
  flex-direction: column;
}

.HorizontalCell {
  background-color: #24ad24;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.SecondarySectionBody {
  background-color: #ffcdd4;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  color: black;
  display: flex;
  flex-direction: column;
}

.SecondaryHorizontalCell {
  background-color: #f53672;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px;
  color: black;
}

a {
  color: #f53672;
}

.HorizontalCellHeader {
  background-color: #24ad24;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn-primary, .btn-primary:focus {
  background-color: #f53672;
  border-color: #f53672;
}

.btn-primary:hover, .btn-primary:active {
  background-color: #f53672;
  border-color: #f53672;
}

.modal-header {
  background-color: #24ad24;
  color: white;
}

.text-muted {
  color: #f53672 !important;
}

canvas {
  position: sticky;
  position: -webkit-sticky;
}

.hscroll {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hscroll::-webkit-scrollbar {
  display: none;
}

.wish-check {
  position: absolute;
  top: -5pt;
  right: -5pt;
  width: 20pt;
  height: 20pt;
  border-radius: 10pt;
  background-color: white;
  overflow: hidden;
  color: black;
  text-align: center;
}